.c-text-block {
    margin-bottom: 5rem;
}
.c-text-block__title {
    font-weight: bold;
}
.c-text-block__title__svg {
    padding-right: 1rem;
}
.c-text-block__title__text {
    font-weight: bold;
    font-size: 30px;
    font-family: LatoBlack;
}

%c-text-block__content {
    display: block;
    font-size: 18px;
}
.c-text-block__content {
    @extend %c-text-block__content;

    font-family: $font-family-roboto;
}

.c-text-block__content--last {
    @extend %c-text-block__content;
}

.c-text-block__content__info {
    @extend %c-text-block__content;
}

.c-text-block__content__link {
    @extend %c-text-block__content;
    font-size: 20px;
    text-align: left;
    color: map-get($theme-color, "primary-dk");

    &:hover {
        color: map-get($theme-color, "primary-dkr");
    }
    &:active {
        color: map-get($theme-color, "primary-dkst");
    }

    &.c-text-block__content__link--disabled {
        color: map-get($theme-color, "light");
        cursor: default;

        &:active,
        &:hover {
            color: map-get($theme-color, "light");
        }
    }
}
