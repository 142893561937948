.latam-c-footer {
    background-color: map-get($theme-color,"lightest");
    border-top: none;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    padding-right: 0;
}
.latam-c-footer__content {
    color: map-get($theme-color,"darkest");
    flex-grow: 1;
    font-family: $font-family-roboto;
}

.latam-c-footer__logos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: map-get($theme-color,"lightest");
    //flex-shrink: 1;
}

.latam-c-footer__logos__logo {
    padding: 0.5rem;
    margin-top: auto;
    margin-bottom: auto;
    & .nav-link {
        padding: 0;
    }
}
