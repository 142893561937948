@import "btn";

.latam {
    background-color: map-get($theme-color,"lightest");
    flex-flow: column nowrap;
}

body {
    min-width: 991px; // no responsiveness
}

p {
    white-space: pre-line; // supports translations with \n
}
