.latam-l-body {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    flex-grow: 1;
}
.latam-l-body-c-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    & > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.latam-l-body-c-content {
    z-index: 9;
    margin: 2rem 1rem;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
}
