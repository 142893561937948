.observatory-c-header {
    padding: 0.5rem 0rem 0.5rem 1.5rem;

    & .c-header-brand {
        width: 150px;

        & img {
            width: 100%;
        }
    }
}
