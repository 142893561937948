.p-observatory {
    //height: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    & > div {
        flex-grow: 1;
    }
}

%p-observatory__section {
    background-color: map-get($theme-color,"primary-dkst");
    height: 100%;
    //padding: 1.5rem 2.5rem;
    color: theme-color("lightest");
    padding: 1.5rem 2.5rem 1rem 1.5rem;
}
.p-observatory__section { @extend %p-observatory__section;}
.p-observatory__section--alt {
    @extend %p-observatory__section;

}
.p-observatory__section--lt {
    @extend %p-observatory__section;
    background-color: map-get($theme-color,"lightest");
    color: map-get($theme-color,"darkest");
}

.p-observatory__loader {
    transition: opacity 350ms ease-in-out;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize(black, 0.25);
    z-index: 9;
    box-shadow: 1px 1px 19px black;
    backdrop-filter: blur(2px);

    &.m-visible {
        opacity: 1;
    }

    &.m-hidden {
        transition: opacity 350ms ease-in-out;
        z-index: -1;
    }
}

.p-observatory__loader__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9;
}
