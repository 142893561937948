.observatory-btn--primary-inverse {
    background-color: transparent;
    color: map-get($theme-color,"primary-dkst");

    &:hover,
    &:focus {
        background-color: map-get($theme-color,"primary-dkst");
        color: map-get($theme-color,"lightest");

        & > path {
            color: map-get($theme-color,"lightest");
        }
    }

    &.observatory-btn--active {
        background-color: map-get($theme-color,"primary-dkst");
        color: map-get($theme-color,"lightest");

        & > path {
            color: map-get($theme-color,"lightest");
        }
    }
}

.observatory-btn--alternate-inverse {
    background-color: map-get($theme-color,"secondary") !important;
    color: map-get($theme-color,"lightest") !important;
    border-color: map-get($theme-color,"secondary") !important;

    &:hover,
    &:focus {
        background-color: map-get($theme-color,"secondary-dk") !important;
        color: map-get($theme-color,"lightest") !important;
        border-color: map-get($theme-color,"secondary-dk") !important;
        

        & > path {
            color: map-get($theme-color,"lightest");
        }
    }

    &.observatory-btn--active {
        background-color: map-get($theme-color,"secondary");
        color: map-get($theme-color,"lightest");

        & > path {
            color: map-get($theme-color,"lightest");
        }
    }
}