.c-stacked-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;
}
.c-stacked-info__icon {
    padding-right: 0.5rem;
}
.c-stacked-info__content {
    display: flex;
    flex-flow: column nowrap;
}
.c-stacked-info__content__main {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    font-family: LatoBlack;
}
.c-stacked-info__content__sub {
    line-height: 1;
    font-size: 18px;
    font-family: $font-family-roboto;
}
