$primary-color: map-get($theme-colors, "lightest");
@import '~loaders.css/src/animations/line-scale-pulse-out.scss';

.loader-hidden {
    display: none;
    opacity: 0;
    transition: opacity 350ms ease-in-out,
        display 0ms ease 350ms;
}
.loader-active {
    transition: opacity 350ms ease-in-out;
    display: block;
    opacity: 1;
}
