%observatory-header-btn {
    @extend %btn--reset;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 0.1rem!important;
}
.observatory-header-btn {
    @extend %observatory-header-btn;

    &.c-header-nav-link {
        @extend %observatory-header-btn;

    }
}

%observatory-header-btn__item {
    display: block;
    line-height: 1;
    font-family: LatoBold;
    color: map-get($theme-color,"primary-dkr");
    & > img {
        display: block;
    }

    &:first-child {
        padding-right: 0.5rem;
    }

    & > .c-icon {
        width: 1.1rem;
        height: 1.1rem;
        font-size: 1.1rem;
        vertical-align: middle;
        margin: 0.21875rem 0;
    }
}

.observatory-header-btn__inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.observatory-header-btn__item {
    @extend %observatory-header-btn__item;
}
