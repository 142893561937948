.c-observatory-graph {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    height: calc(100% - 1.9rem); // chartjs grows once data is plotted, this is an attempt to offset that effect.

}
.c-observatory-graph__content {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;

    & .observatory-c-visualisation {
        padding: 0 2.5rem;
        flex-grow: 1;
    }


    & .observatory-c-visualisation__graph {
        height: 100%;   

        padding-bottom: 0;
        display: flex;
        flex-flow: column nowrap;
    }

    & .observatory-c-visualisation__download__link {
        text-align: right;
        padding-top: 1.5rem;
    }

    & .observatory-c-visualisation-graph {
        flex-grow: 1;
    }
}
