@font-face {
    font-family: "LatoBlack";
    src: local("LatoBlack"),
        url("./Lato-Black.ttf")
        format("truetype");
}

@font-face {
    font-family: "LatoBold";
    src: local("LatoBold"),
        url("./Lato-Bold.ttf")
        format("truetype");
}