.latam {
    .latam-sidebar {
        background-color: map-get($theme-color,"primary");
        & .latam-sidebar__header {
            text-align: left;
            font-size: 18px;
            font-weight: bold;
        }
        .c-sidebar-nav {
            box-shadow: 1px -16px 8px -6px rgba(0, 0, 0, 0.35) inset;
            padding: 1rem 0;
        }
        .c-sidebar-nav-dropdown.c-show {
            background: transparent;
        }
        .c-icon {
            margin: 2px 10px;
            height: 25px;
            width: 25px;
            font-size: 30px;
        }
        .c-sidebar-nav-dropdown-toggle svg {
            height: 25px;
            width: 25px;
        }
        .c-sidebar-nav-dropdown {
            margin-bottom: 0;
        }
        .c-sidebar-nav-dropdown-items {
            margin-top: 0;
        }

        .first-level-menu a {
            padding-left: 35px;
        }
        .c-sidebar-nav-item {
            margin-bottom: 0px;
        }
        .c-sidebar-nav-dropdown-toggle::after {
            display: block;
            -webkit-flex: 0 1 8px;
            flex: 0 1 8px;
            height: 20px;
            margin-right: 10px;
            content: "";
            background-repeat: no-repeat;
            background-position: center;
            transition: -webkit-transform 0.3s;
            transition: transform 0.3s;
            transition: transform 0.3s, -webkit-transform 0.3s;
        }
        & .c-sidebar-nav-link {
            padding: 5px 2rem;
            color: map-get($theme-color,"lighter") !important;
            white-space: pre-wrap;
            line-height: 1;

            & > svg path {
                fill: map-get($theme-color,"lighter");
            }

            // link states

            &:hover,
            &:focus {
                background-color: map-get($theme-color,"primary-lt");
                //color: theme-color("primary");
                & > svg path {
                    fill: map-get($theme-color,"primary-dk");
                }
            }

            &:active,
            &.c-active {
                background-color: map-get($theme-color,"lightest");
            }

            &.c-active {
                color: map-get($theme-color,"primary-dk");
                & > svg path {
                    fill: map-get($theme-color,"lightest");
                }
            }
        }
        .c-sidebar-nav-dropdown-toggle {
            padding: 5px 2rem;
            color: map-get($theme-color,"lighter");

            &:hover,
            &:focus {
                background-color: map-get($theme-color,"primary-dk");
                color: map-get($theme-color,"lighter");
                & > svg path {
                    fill: map-get($theme-color,"lighter");
                }
            }

            &:active,
            &.c-active {
                background-color: map-get($theme-color,"primary-dk");
            }

            &.c-active {
                color: map-get($theme-color,"lighter");
                & > svg path {
                    fill: map-get($theme-color,"lighter");
                }
            }
        }
        .is-available {
            .c-sidebar-nav-link {
                color: map-get($theme-color,"lightest") !important;
                &:hover,
                &:focus {
                    background-color: map-get($theme-color,"primary-lt") !important;
                    //color: theme-color("primary") !important;
                    //color: theme-color("lightest") !important;
                    & > svg path {
                        fill: map-get($theme-color,"lightest");
                    }
                }

                &:active,
                &.c-active {
                    background-color: map-get($theme-color,"primary-dk");
                }

                &.c-active {
                    color: map-get($theme-color,"primary-dk");
                    & > svg path {
                        fill: map-get($theme-color,"primary-dk");
                    }
                }
            }
        }
        .is-available-toggle {
            .c-sidebar-nav-dropdown-toggle {
                color: map-get($theme-color,"lightest") !important;
            }
        }
        .sidebar-footer {
            text-align: left;
            font-size: 11px;
            font-weight: bold;
            padding: 20px;
            a {
                color: map-get($theme-color,"lightest");
            }
        }
    }
}
