// Variable overrides
$theme-color: (

    "primary": #346caf,
    "primary-lt": #9eb5d5,
    "primary-dk": #3e495f,
    "primary-dkr": #3c4b64,
    "primary-dkst": #2E3543,
    
    "secondary": #28B0C0,
    "secondary-dk": #008E9F,


    "light": #848a92,
    "lighter": #a1a1a1,
    "lightest": #ffffff,
    "lightest-transparent-lt": rgba(255, 255, 255, 0.25),

    "darkest": black,

);
$font-family-sans-serif:
    // Safari for macOS and iOS (San Francisco)
    -apple-system,
        // Chrome < 56 for macOS (San Francisco)
    BlinkMacSystemFont,
        // Windows
    "Montserrat",
        // Android
    "Montserrat",
        // Basic web fallback
    "Montserrat", Arial, sans-serif,
        // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-roboto:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
