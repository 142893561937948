.c-observatory-map-indicators {
    z-index: 1;
}
.mapboxgl-popup {
    max-width: unset !important;
}
.c-observatory-map-popup {
    .c-observatory-map-popup__title {
        font-size: 1rem;
        margin-bottom: 0.2rem;
    }
    .c-observatory-map-popup__content {
        font-size: 0.7rem;
        span {
            display: block;
            line-height: normal;
        }
    }
}