/**
 * Consistent y spacing on c-main
 */
$fixed-footer-height: 50px;
.c-main {
    padding-top: 0px;
    display: flex;
    flex-flow: column nowrap;
}

.c-wrapper {
    min-height: initial;
}
