.latam-u-reset-height { height: auto;}
.latam-u-flex-grow { flex-grow: 1;}
.latam-u-apply-flex-columns-to-children {
    display: flex;
    flex-flow: column nowrap;
    > div {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
    }
}

.latam-u-no-box-shadow {
    box-shadow: none!important;
}
