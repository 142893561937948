.observatory-dropdown {
    background-color: map-get($theme-color,"primary");
    max-height: 378px;    

    & > .dropdown-item {
        color: map-get($theme-color,"lightest");
        transition: all ease-in-out 350ms;

        &:hover {
            background-color: map-get($theme-color,"primary-lt");
        }

        &.disabled {
            pointer-events: none;
            color: map-get($theme-color,"light");
            &:hover {
                background-color: map-get($theme-color,"primary");
            }
        }
        &.active {
            pointer-events: none;
            background-color: map-get($theme-color,"primary");
            font-weight: bold;
            &:hover {
                background-color: map-get($theme-color,"primary");
            }
        }
    }
}
