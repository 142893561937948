/**
 * Make the container occupy all available y space
 */
.container-fluid {
    height :100%;

    & > div {
        height: 100%;
    }
}
